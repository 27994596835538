import {
    getWithAuth, putWithAuth
} from "utilities/api";

export async function getGlobalSettings(accessToken: string) {
    var url = window.env.baseURL + `globalsettings`;
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function putGlobalSettings(accessToken: string, 
    params: any) {
    var url = window.env.baseURL + `globalsettings`;
        return putWithAuth(url, accessToken, params).then((response) => {
        return response;
    });
}
