import { Box, styled } from "@mui/material";
import ContainerWrapper from "components/Container/ContainerWrapper";
import PropTypes from "prop-types";
import { FC, ReactNode } from "react";

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(1)};
`
);

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <PageTitle sx={{ backgroundColor: "#fff", marginBottom: "2vh" }}>
      <ContainerWrapper>{children}</ContainerWrapper>
    </PageTitle>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitleWrapper;
