import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import AppsIcon from "@mui/icons-material/Apps";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import PestControlIcon from "@mui/icons-material/PestControl";
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {Box, Button, Divider, lighten, List, ListItem, ListItemText, Popover, Typography,} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import {logOut, selectAuth} from "slices/authSlice";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {getRoleName, isAdmin} from "utilities/helper";

const UserBoxButton = styled(Button)(
    ({theme}) => `
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({theme}) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    let user = useAppSelector(selectAuth);

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(logOut());
        localStorage.removeItem("crashTableInformation");
        localStorage.removeItem("filter-state");
        localStorage.removeItem("bucketTableInformation");
        navigate("login");
    };

    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <UserBoxText>
                    <UserBoxLabel variant="body1">
                        {user.name ? user.name : user.userIdentifier}
                    </UserBoxLabel>
                    <UserBoxDescription variant="body2">
                        {getRoleName(user.role)}
                    </UserBoxDescription>
                </UserBoxText>
                <ExpandMoreTwoToneIcon sx={{ml: 1}}/>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuUserBox sx={{minWidth: 210}} display="flex">
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            {user.name ? user.name : user.userIdentifier}
                        </UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {getRoleName(user.role)}
                        </UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider/>
                <List component="nav">
                    {isAdmin(user.role) ? (
                        <>
                            <ListItem
                                button
                                to="applications"
                                component={NavLink}
                                onClick={() => setOpen(false)}
                            >
                                <AppsIcon fontSize="small"/>
                                <ListItemText primary="Manage Applications"/>
                            </ListItem>
                            <ListItem
                                button
                                to="users"
                                component={NavLink}
                                onClick={() => setOpen(false)}
                            >
                                <GroupsIcon fontSize="small"/>
                                <ListItemText primary="Manage Users"/>
                            </ListItem>

                            <ListItem
                                button
                                to="defectTracker"
                                component={NavLink}
                                onClick={() => setOpen(false)}
                            >
                                <PestControlIcon fontSize="small"/>
                                <ListItemText primary="Manage Defect Tracker"/>
                            </ListItem>

                            <ListItem
                                button
                                to="symbolicationManager"
                                component={NavLink}
                                onClick={() => setOpen(false)}
                            >
                                <AssignmentIcon fontSize="small"/>
                                <ListItemText primary="Manage Symbolication"/>
                            </ListItem>

                            <ListItem
                                button
                                to="bucketManager"
                                component={NavLink}
                                onClick={() => setOpen(false)}
                            >
                                <SettingsApplicationsIcon fontSize="small"/>
                                <ListItemText primary="Manage Buckets"/>
                            </ListItem>
                        </>
                    ) : null}
                    <ListItem
                        button
                        to="/profile"
                        component={NavLink}
                        onClick={() => setOpen(false)}
                    >
                        <AccountBoxTwoToneIcon fontSize="small"/>
                        <ListItemText primary="My Profile"/>
                    </ListItem>
                </List>
                <Divider/>
                <Box sx={{m: 1}}>
                    <Button color="error" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon sx={{mr: 1}}/>
                        Log out
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;
