import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import ContainerWrapper from "components/Container/ContainerWrapper";
import ListIcon from "@mui/icons-material/List";
import PageTitleWrapper from "components/PageTitleWrapper";
import PageHeader from "pages/bucketManager/components/PageHeader";
import CleaningServices from '@mui/icons-material/CleaningServices';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useContext, useState, useEffect } from "react";
import dialogContext, { DialogType } from "utilities/dialogContext";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import { getRebucketSummary, clearRebucketQueue, putAllToBucketQueue } from "src/services/bucketServices";
import { Log } from "utilities/helper";
import { LogLevel } from "@azure/msal-browser";
import { Messages } from "utilities/Messages";
import { getGlobalSettings, putGlobalSettings } from "src/services/globalSettingsService";
import FailedRebucketingInfoTabs from "./components/FailedRebucketingInfoTabs";

interface RebucketSummary {
    queueSize: number
    unprocessedCrashCount: number
}

interface RebucketQueueOrder {
    rebucketQueueOrderSettings: number
}

function BucketManager() {
    var { setDialogType, setShowDialog, setTitle, setMessage, setOnClickOk, setOnClickCancel } =
        useContext(dialogContext);
        
    const [maximumCrashToBucket, setMaximumCrashToBucket] = useState<number>(0);
    const [disableBucketAllButton, setDisableBucketAllButton] = useState<boolean>(false);

    const auth = useAppSelector(selectAuth);
    const [rebucketSummary, setRebucketSummary] = useState<RebucketSummary>();
    const [rebucketQueueOrder, setRebucketQueueOrder] = useState<RebucketQueueOrder>();

    const loadRebucketSummary = () => {
        getRebucketSummary(auth.authToken).then(
            (response) => {
                if (response[0]) {
                    var result = response[0];
                    if (result.success) {
                        setRebucketSummary(result.data);
                    }
                } else {
                    var error = response[1];
                    Log(error, LogLevel.Error);
                }
            }
        );
    }

    const loadGlobalSettings = () => {
        getGlobalSettings(auth.authToken).then(
            (response) => {
                if (response[0]) {
                    var result = response[0];
                    if (result.success) {
                        setRebucketQueueOrder(result.data);
                    }
                } else {
                    var error = response[1];
                    Log(error, LogLevel.Error);
                }
            }
        );
    }

    useEffect(() => {
        loadRebucketSummary();
        loadGlobalSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [auth]);

    const confirmClearQueue = () => {
        setTitle("Alert!");
        setMessage(Messages.ClearRebucketQueueConfirmation);
        setDialogType(DialogType.CONFIRMATION);
        setOnClickOk(() => () => {
            clearQueue();
        });
        setOnClickCancel(() => () => setShowDialog(false));
        setShowDialog(true);
    }

    const clearQueue = () => {
        clearRebucketQueue(auth.authToken).then(
            (response) => {
                if (response[0]) {
                    loadRebucketSummary();
                    setShowDialog(false);
                } else {
                    var error = response[1];
                    Log(error, LogLevel.Error);
                    setTitle("Error");
                    setMessage(Messages.SomethingWrong);
                    setDialogType(DialogType.MESSAGE);
                    setOnClickOk(() => () => setShowDialog(false));
                    setShowDialog(true);
                }
            }
        );
    }

    const BucketAll = () => {
        setTitle("Alert!");
        setMessage(Messages.BucketAllConfirmation.replace("{0}", maximumCrashToBucket.toString()));
        setDialogType(DialogType.CONFIRMATION);
        setOnClickOk(() => () => {
            setShowDialog(false);
            setMessage(Messages.LongOperation);
            setDialogType(DialogType.MESSAGE);
            setShowDialog(true);
            setOnClickOk(() => () => {
                setShowDialog(false)
                setDisableBucketAllButton(true);
                setTimeout(() => {
                    setDisableBucketAllButton(false);
                }, 120000);
                putAllToBucketQueue(auth.authToken, maximumCrashToBucket).then(
                    (response) => {
                        if (response[0]) {
                            setDisableBucketAllButton(false);
                            var title;
                            var result = response[0];
                            if (result.success) {
                                title = "Success";
                                setMessage(result.message);
                            }
                            else {
                                title = "Error";
                                setMessage(Messages.SomethingWrong);
                            }
                            setTitle(title);
                            setDialogType(DialogType.MESSAGE);
                            setOnClickOk(() => () => setShowDialog(false));
                        } else {
                            var error = response[1];
                            Log(error, LogLevel.Error);
                            setTitle("Error");
                            setMessage(Messages.SomethingWrong);
                            setDialogType(DialogType.MESSAGE);
                            setOnClickOk(() => () => setShowDialog(false));
                        }
                        setShowDialog(true);
                    }
                );
            }
            );
        });
        setOnClickCancel(() => () => setShowDialog(false));
        setShowDialog(true);
    }

    const updateRebucketQueueOrderSettings = () => {
        const updatedGlobalSettings : RebucketQueueOrder =  {
            rebucketQueueOrderSettings : rebucketQueueOrder != undefined ? 
                        1 - rebucketQueueOrder.rebucketQueueOrderSettings : 0
        };
        putGlobalSettings(auth.authToken, updatedGlobalSettings).then(
            (response) => {
                if (response[0]) {
                    var result = response[0];
                    if (result.success) {
                        setRebucketQueueOrder(result.data);
                    }
                } else {
                    var error = response[1];
                    Log(error, LogLevel.Error);
                }
            }
        );
    }

    return (
        <>
            <Helmet>
                <title>Bucket Manager</title>
            </Helmet>

            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <ContainerWrapper>
                <Grid container spacing={1} justifyContent={"center"}>
                    <Grid xs={6} sm={6} md={3} item>
                        <Card
                            sx={{
                                px: 1,
                                height: "100%",
                            }}
                        >
                            <CardContent>
                                {<DeviceHubIcon color="primary" fontSize="large" />}
                                <Typography
                                    sx={{
                                        pt: 1,
                                    }}
                                    variant="h5"
                                >
                                    Bucket unbucketed crashes
                                </Typography>
                                <Grid style={{ marginTop: "20px" }}>
                                    <TextField placeholder="Rebucket Crash Limit" type="number" onChange={(e)=>{
                                        setMaximumCrashToBucket(parseInt(e.target.value));
                                    }}/>
                                </Grid>
                                <Box
                                    sx={{
                                        justifySelf: "flex-end",
                                        pt: 3,
                                    }}
                                >
                                    <Button
                                        size="large"
                                        color="error"
                                        variant="contained"
                                        onClick={BucketAll}
                                        disabled={disableBucketAllButton}
                                    >
                                        Bucket
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} item>
                        <Card
                            sx={{
                                px: 1,
                                height: "100%",
                            }}
                        >
                            <CardContent>
                                {<ListIcon color="primary" fontSize="large" />}
                                <Typography
                                    sx={{
                                        pt: 1,
                                    }}
                                    variant="h5"
                                >
                                    Crashes in Rebucket task queue: {rebucketSummary?.queueSize}
                                </Typography>
                                <Typography
                                    sx={{
                                        pt: 1,
                                    }}
                                    variant="h5"
                                >
                                    Remaining Crashes to Bucket: {rebucketSummary?.unprocessedCrashCount}
                                </Typography>
                                <Typography
                                    sx={{
                                        pt: 2,
                                    }}
                                    variant="h5"
                                >
                                    Rebucket Queue Crash Order
                                </Typography>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography>Most Recent Last</Typography>
                                    <Switch
                                        checked={rebucketQueueOrder?.rebucketQueueOrderSettings == 1}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {updateRebucketQueueOrderSettings()}}
                                    />
                                    <Typography>Most Recent First</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} item>
                        <Card
                            sx={{
                                px: 1,
                                height: "100%",
                            }}
                        >
                            <CardContent>
                                {<CleaningServices color="primary" fontSize="large" />}
                                <Typography
                                    sx={{
                                        pt: 1,
                                    }}
                                    variant="h5"
                                >
                                    Clear Rebucket Queue
                                </Typography>
                                <Box
                                    sx={{
                                        justifySelf: "flex-end",
                                        pt: 9,
                                    }}
                                >
                                    <Button
                                        size="large"
                                        color="error"
                                        variant="contained"
                                        onClick={confirmClearQueue}
                                    >
                                        Clear Queue
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ContainerWrapper >
            <FailedRebucketingInfoTabs loadRebucketSummary={loadRebucketSummary}/>
        </>
    )
}

export default BucketManager;