import { Box, CardMedia, styled } from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 120px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 120px;
        height: 25px;
`
);

const Logo = () => {
  return (
    <LogoWrapper to="/dashboard">
      <LogoSignWrapper>
        <CardMedia
          component="img"
          image="/static/images/logo/corsair-logo.svg"
          alt="Corsair"
        />
      </LogoSignWrapper>
    </LogoWrapper>
  );
};

export default Logo;
