import { Box, Grid, ListItem } from "@mui/material";

export default function NoNewNotifications() {
    return (
        <Box
            sx={{ p: 2, minWidth: 300, minHeight: 50, display: { xs: 'block', sm: 'flex' } }}
        >
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <p style={{ textAlign: 'center' }}>
                        <b>No new notifications</b>
                    </p>
                </Grid>
            </Grid>
        </Box>
    );
}