import { Container, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface ContainerWrapperProps {
    children?: ReactNode;
    sx?: SxProps
}

export default function ContainerWrapper({ children, sx }: ContainerWrapperProps) {
    const sideMarginPercentage = 8;
    const width = 100 - (2 * sideMarginPercentage);
    
    return (
        <Container
            maxWidth={false}
            sx={{
                ...sx,
                marginLeft: `${sideMarginPercentage}%`,
                marginRight: `${sideMarginPercentage}%`,
                width: `${width}%`
            }}>
            {children}
        </Container >
    );
}