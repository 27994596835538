import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface httpStatus {
  code: number;
  message: string;
}

const initialState: httpStatus = {
  code: 0,
  message: "",
};

export const httpStatusSlice = createSlice({
  name: "httpErrorHandler",
  initialState,
  reducers: {
    setStatus: (state, Action: { payload: httpStatus }) => {
      state.code = Action.payload.code;
      state.message = Action.payload.message;
    },
  },
});

export const { setStatus } = httpStatusSlice.actions;

export const selectHTTPStatus = (state: RootState) => state.httpStatus;

export default httpStatusSlice.reducer;
