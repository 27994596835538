import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";

import dialogContext, { DialogType } from "utilities/dialogContext";

import DialogHeader from "../DialogBox/DialogHeader";

export default function ConfirmationDialog() {
  var {
    dialogType,
    showDialog,
    title,
    message,
    verificationText,
    setVerificationText,
    onClickOk,
    onClickCancel,
  } = useContext(dialogContext);

  var messageItems = message.split("<br/>").slice(1);
  message = message.split("<br/>")[0];

  const [inputText, setInputText] = useState("");

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setInputText("");
        setVerificationText("");
        if (onClickCancel) onClickCancel();
      }}
      PaperProps={{ sx: { maxHeight: "35%", minWidth: "30%", p: 0.5 } }}
    >
      <DialogHeader
        title={title}
        onClose={() => {
          setVerificationText("");
          setInputText("");
          if (onClickCancel) onClickCancel();
        }}
      />
      <Box sx={{ pl: 2, pb: 0.5, pr: 2 }}>{message}</Box>
      <DialogContent
        sx={{
          pl: 4,
          pt: 0.5,
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {messageItems.map((element) => (
            <li key={element}>{element}</li>
          ))}
        </DialogContentText>
      </DialogContent>
      {verificationText !== "" ? (
        <Box sx={{ pl: 2, pr: 4, pt: 1 }}>
          To confirm the operation type <b>{verificationText}</b> in the field.
          <TextField
            sx={{ pt: 1 }}
            variant="outlined"
            size="small"
            fullWidth
            placeholder={verificationText}
            onChange={(e) => setInputText(e.target.value)}
          ></TextField>
        </Box>
      ) : null}
      <DialogActions sx={{ pr: 2, pb: 1.75, pt: 2 }}>
        {dialogType === DialogType.CONFIRMATION ? (
          <Button
            sx={{ borderRadius: 0.5 }}
            variant="outlined"
            onClick={() => {
              setInputText("");
              setVerificationText("");
              onClickCancel();
            }}
            autoFocus
          >
            No
          </Button>
        ) : null}
        <Button
          variant="contained"
          sx={{ borderRadius: 0.5 }}
          onClick={() => {
            setInputText("");
            setVerificationText("");
            onClickOk();
          }}
          autoFocus
          disabled={verificationText !== "" && verificationText !== inputText}
        >
          {dialogType === DialogType.CONFIRMATION ? "Yes" : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
