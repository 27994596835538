import { createSlice } from "@reduxjs/toolkit";

import Crash from "dto/crashDto";
import { RootState } from "store/store";

interface crashType {
  List: Crash[];
  isLoading: boolean;
}

const initialState: crashType = {
  List: [],
  isLoading: true
};

export const crashSlice = createSlice({
  name: "crash",
  initialState,
  reducers: {
    setCrashData: (state, Action: { payload: Crash[]; type: string }) => {
      state.List = Action.payload.map((item) => {
        return { ...item };
      });
      state.isLoading = false;
    },
    setIsLoading: (state, Action: { payload: boolean }) => {
      state.isLoading = Action.payload;
    },
  },
});

export const { setCrashData, setIsLoading } = crashSlice.actions;

export const selectCrash = (state: RootState) => state.crash;

export default crashSlice.reducer;
