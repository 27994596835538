import { IFilterControlFilterValue } from "react-filter-control/interfaces";

export enum FilterNames {
  ApplicationName = 1,
  Id = 2,
  ExceptionCode = 3,
  ApplicationId = 4,
  OSName = 5,
  CreatedAt = 6,
  ApplicationVersion = 7,
  OSVersion = 8,
  OSBuildNumber = 9,
  Architecture = 10,
  Platform = 11,
  Priority = 12,
  InstallationIdentifier = 13,
  CountOfSimilarCrash = 14,
  CountOfUniqueUser = 15,
  LastOccurance = 16,
  StackTrace = 18,
  Comment = 19,
}

export enum FilterOperations {
  IsEqualTo = 1,
  IsNotEqualTo = 2,
  IsLessThan = 3,
  IsLessThanOrEqualTo = 4,
  IsGreaterThan = 5,
  IsGreaterThanOrEqualTo = 6,
  IsEmpty = 7,
  IsNotEmpty = 8,
  Contains = 9,
  NotContains = 10,
  StartsWith = 11,
  EndsWith = 12,
  IsOnOrBefore = 13,
  IsOnOrAfter = 14,
  IsNull = 15,
  IsNotNull = 16,
}

export enum GroupNames {
  ExceptionCode = 1,
  ApplicationId = 2,
  OSName = 3,
  Timestamp = 4,
  ApplicationVersion = 5,
  ApplicationName = 6,
  OSVersion = 7,
  OSBuildNumber = 8,
  Architecture = 9,
  Platform = 10,
  Priority = 11,
  InstallationIdentifier = 12
}

export enum ArchiveStatus {
  NotArchived = 0,
  Archived = 1
}

export enum Priority {
  Low = 0,
  Medium = 1,
  High = 2
}

export interface filterType {
  filterName: FilterNames;
  filterValue: string;
  filterOperation: FilterOperations;
}

export interface groupType {
  groupName: GroupNames;
}

export default interface crashRequestParams {
  filters: filterType[];
  searchFilters?: IFilterControlFilterValue[];
  groups: groupType[];
  currentPageNumber: number;
  itemPerPage: number;
  includeArchievedApp: boolean;
  includeDeletedApp: boolean;
  uniqueOnly: boolean;
  archiveStatus?: ArchiveStatus;
  sortField?: SortField;
  ascending?: boolean;
  includeLinkedCrashes: boolean;
}

export enum SortField {
  Id = 1,
  ExceptionCode = 2,
  ApplicationName = 3,
  ApplicationVersion = 4,
  OSName = 5,
  OSVersion = 6,
  OSBuildNumber = 7,
  Architecture = 8,
  Platform = 9,
  CountOfSimilarCrash = 10,
  Timestamp = 11,
  Priority = 12,
  CountOfUniqueUser = 13,
  LastOccurance = 14,
  InstallationIdentifier = 15
}

export interface LinkedCrash {
  rootCrashId: number,
  linkedCrashId: number
}

export interface unlinkCrashDto {
  crashIdToUnlink : number
}

export enum PageType {
  Dashboard = 0,
  Crashes = 1
}

export enum SearchGroupNames {
  AND = 1,
  OR = 2
}

const enum SearchNumberOptionEnum {
  Id = 2,
  CountOfSimilarCrash = 14,
  CountOfUniqueUser = 15,
};

const enum SearchStringOptionEnum {
  ApplicationName = 1,
  ExceptionCode = 3,
  OSName = 5,
  ApplicationVersion = 7,
  OSVersion = 8,
  OSBuildNumber = 9,
  Architecture = 10,
  Platform = 11,
  Priority = 12,
  InstallationIdentifier = 13,
  StackTrace = 18,
  Comment = 19
};

export enum BucketType {
  All = 0,
  Trending = 1
}