import {
  deleteWithAuthHasBody,
  getWithAuth,
  postWithAuth,
  putWithAuth,
} from "utilities/api";

export async function getDefectTracker(
  accessToken: string,
  trackerType: number
) {
  var url = window.env.baseURL + "defectTracker/" + trackerType;
  return getWithAuth(url, accessToken).then((response) => {
    return response;
  });
}

export async function getProjectList(accessToken: string, trackerType: number) {
  var url = window.env.baseURL + "defectTracker/" + trackerType + "/project";
  return getWithAuth(url, accessToken).then((response) => {
    return response;
  });
}

export async function getTrackedApplication(
  accessToken: string,
  trackerType: number,
  applicationId: number
) {
  var url =
    window.env.baseURL +
    "defectTracker/" +
    trackerType +
    "/application/" +
    applicationId;
  return getWithAuth(url, accessToken).then((response) => {
    return response;
  });
}

export async function updateDefectTracker(
  accessToken: string,
  trackerType: number,
  params: any
) {
  var url = window.env.baseURL + "defectTracker/" + trackerType;

  return putWithAuth(url, accessToken, params).then((response) => {
    return response;
  });
}

export async function createDefectTracker(
  accessToken: string,
  trackerType: number,
  params: any
) {
  var url = window.env.baseURL + "defectTracker";

  return postWithAuth(url, accessToken, params).then((response) => {
    return response;
  });
}

export async function trackApplication(accessToken: string, params: any) {
  var url = window.env.baseURL + "defectTracker/track";

  return postWithAuth(url, accessToken, params).then((response) => {
    return response;
  });
}

export async function untrackApplication(accessToken: string, params: any) {
  var url = window.env.baseURL + "defectTracker/track";

  return deleteWithAuthHasBody(url, accessToken, params).then((response) => {
    return response;
  });
}

export async function createDefectTrackerIssue(
  accessToken: string,
  params: any
) {
  var url = window.env.baseURL + "defectTracker/createIssue";

  return postWithAuth(url, accessToken, params).then((response) => {
    return response;
  });
}

export async function getIssueTypes(
  accessToken: string,
  trackerType: number,
  projectId: number
) {
  var url =
    window.env.baseURL +
    "defectTracker/" +
    trackerType +
    "/issueType/" +
    projectId;
  return getWithAuth(url, accessToken).then((response) => {
    return response;
  });
}
