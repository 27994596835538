import { MarkCrashNotificationDto, RemoveCrashNotificationDto } from "src/dto/notificationDto";
import { deleteWithAuth, deleteWithAuthHasBody, getWithAuth, putWithAuth } from "src/utilities/api";

export async function getNotifications(
	accessToken: string,
	currentPageNumber: number,
	itemPerPage: number
) {
	const url = window.env.baseURL + `notification?currentPageNumber=${currentPageNumber}&itemPerPage=${itemPerPage}`;
	return getWithAuth(url, accessToken).then((response) => {
		return response;
	});
}

export async function removeCrashNotification(
	accessToken: string,
	params: RemoveCrashNotificationDto
) {
	const url = window.env.baseURL + `notification/${params.crashId}`;
	return deleteWithAuthHasBody(url, accessToken, params).then((response) => {
		return response;
	});
}

export async function removeAllCrashNotification(
	accessToken: string
) {
	const url = window.env.baseURL + "notification/all";
	return deleteWithAuth(url, accessToken).then((response) => {
		return response;
	});
}

export async function markCrashNotification(
	accessToken: string,
	crashId: number,
	isRead: boolean
) {
	const url = window.env.baseURL + `notification/mark/${crashId}`;
	return putWithAuth(url, accessToken, {isRead: isRead}).then((response) => {
		return response;
	});
}

export async function countUnreadNotification(
	accessToken: string
) {
	const url = window.env.baseURL + "notification/count-unread";
	return getWithAuth(url, accessToken).then((response) => {
		return response;
	});
}