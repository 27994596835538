import {Box, Card, Grid, Pagination, TablePagination, TextField, Typography,} from "@mui/material";
import React, {ChangeEvent, useState} from "react";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const INVALID_PAGE_NUMBER: number = Number.MIN_SAFE_INTEGER;
  const {count, rowsPerPage, onPageChange, page} = props;
  const [pageInputValue, setPageInputValue] = React.useState<number>(INVALID_PAGE_NUMBER);

  /**
   * Handles the change of the page.
   *
   * @param {any} event - The event that triggered the change.
   * @param {number} value - The new page number.
   */
  const handleChange = (event: any, value: number) => {
    // Calculate the total number of pages
    const totalPage = Math.ceil(count / rowsPerPage);

    // If there are no pages, return immediately
    if (!totalPage) {
      return;
    }

    // If the value is greater than the total number of pages, then set the page to the last page
    if (value > totalPage) {
      onPageChange(event, totalPage - 1);
      return;
    }

    // If the value is less than or equal to 0, then set the page to the first page
    if (value <= 0) {
      onPageChange(event, 0);
      return;
    }
    
    // Otherwise, set the page to the value
    onPageChange(event, value - 1);
  };

  /**
   * Handles the change of the page input value.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event that triggered the change.
   */
  const handleChangePageInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Parse the value from the event target
    const value = parseInt(event.target.value);

    // If the value is truthy (not 0, null, undefined, NaN, '', or false), set the page input value to the value
    if (value) {
      setPageInputValue(value);
    } else {
      // Otherwise, set the page input value to -1
      setPageInputValue(INVALID_PAGE_NUMBER);
    }
  };

  /**
   * Handles the 'Enter' key press on the page input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event that triggered the function.
   */
  const handlePageInputValueOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the 'Enter' key is pressed
    if (event.key === 'Enter') {
      // Call the handleChange function with the event and the page input value (or 0 if the page input value is undefined)
      handleChange(event, pageInputValue);

      // Clear input value
      setPageInputValue(INVALID_PAGE_NUMBER);
    }
  }

  return (
    <>
      <Box sx={{flexShrink: 0, ml: 3}}>
        <Pagination
          shape="rounded"
          count={Math.ceil(count / rowsPerPage)}
          color="primary"
          showFirstButton
          showLastButton
          onChange={handleChange}
          page={page + 1}
        />
      </Box>
      <Box sx={{ml: 2, mr: 2}}>
        <TextField
          size="small"
          type="number"
          label="Go to Page"
          fullWidth
          onChange={handleChangePageInputValue}
          onKeyDown={handlePageInputValueOnKeyDown}
          value={pageInputValue === INVALID_PAGE_NUMBER ? "" : pageInputValue}
        />
      </Box>
    </>
  );
}

const TableFooter = (props: any) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
    props.setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    props.setLimit(parseInt(event.target.value));
  };

  return (
    <div>
      <Card sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
        <Grid
          container
          p={1}
          sx={{ml: 1, mr: 1}}
          display="flex"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item md={1} xs={1} sm={1}>
            <Typography sx={{p: 1}} fontWeight="bold">
              Total: {props.dataLength}
            </Typography>
          </Grid>
          <Grid item md={11} xs={11} sm={11}>
            <TablePagination
              component="code"
              count={props.dataLength}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={
                props.dataLength < props.page * props.limit
                  ? Math.floor(props.dataLength / props.limit)
                  : props.page
              }
              rowsPerPage={props.limit}
              rowsPerPageOptions={[5, 10, 15, 20]}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default TableFooter;
