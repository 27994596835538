import { lazy, Suspense } from "react";
import { RouteObject } from "react-router";
import { Navigate } from "react-router";
import DefectTrackerUI from "pages/defectTracker";
import SuspenseLoader from "components/SuspenseLoader";
import Layout from "layouts/Layout";
import { ArchiveStatus, BucketType } from "dto/crashRequestDto";
import SymbolicationManager from "pages/symbolicationManager";
import BucketManager from "src/pages/bucketManager";
import Blank from "src/pages/blank";

const Loader = (Component: any) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loader(lazy(() => import("pages/dashboard")));

const Crashes = Loader(lazy(() => import("pages/crashes")));
const Buckets = Loader(lazy(() => import("pages/buckets")));
const BucketDetails = Loader(lazy(() => import("pages/bucketDetails")))

const CrashDetails = Loader(lazy(() => import("pages/crashDetails")));

const Applications = Loader(lazy(() => import("pages/applications")));
const Users = Loader(lazy(() => import("pages/users")));
const Profile = Loader(lazy(() => import("pages/profile")));

const Status404 = Loader(lazy(() => import("pages/error")));

const routes: RouteObject[] = [
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "home",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "crashes",
        element: <Crashes type={ArchiveStatus.NotArchived} />,
      },
      {
        path: "buckets",
        element: <Buckets type={BucketType.All} />,
      },

      {
        path: "archives",
        element: <Crashes type={ArchiveStatus.Archived} />,
      },
      {
        path: "trendingBuckets",
        element: <Buckets type={BucketType.Trending} />,
      },
      {
        path: "crash/:id",
        element: <CrashDetails />,
      },
      {
        path: "bucket/:id",
        element: <BucketDetails />,
      },
      {
        path: "applications",
        element: <Applications />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "defectTracker",
        element: <DefectTrackerUI />,
      },
      {
        path: "symbolicationManager",
        element: <SymbolicationManager />,
      },
      {
        path: "bucketManager",
        element: <BucketManager />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
