import { createSlice } from "@reduxjs/toolkit";

import Crash from "dto/crashDto";
import { RootState } from "store/store";

interface crashType {
  List: Crash[];
}

const initialState: crashType = {
  List: [],
};

export const recentCrashSlice = createSlice({
  name: "recentCrash",
  initialState,
  reducers: {
    setRecentCrashData: (state, Action: { payload: Crash[]; type: string }) => {
      state.List = Action.payload.map((item) => {
        return { ...item };
      });
    },
  },
});

export const { setRecentCrashData } = recentCrashSlice.actions;

export const selectRecentCrash = (state: RootState) => state.recentCrash;

export default recentCrashSlice.reducer;
