export class Constant {
	static readonly NetCore = ".NET Core";
	static readonly NetFrameWork = ".NET Framework";
	static readonly Win32 = "Win32";
	static readonly Mac = "Mac";
	static readonly DotNet = "Net";
	static readonly ArchivedCrashSimilarCountThreshold = 50;
	static readonly SymbolicationThreshold = 50;
	static readonly ShowLinkedCrashes = false;
	static readonly MaxDisablePeriodInMSForClearButtons = 60000;
}
