import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';

import LoginButton from 'components/Login';
import Footer from 'layouts/Footer';

const LoginWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const Login = () => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      <LoginWrapper>
        <Helmet>
          <title>Log In</title>
        </Helmet>
        <Container maxWidth="lg">
          <Box sx={{ m: 5, borderRadius: 12 }}>
            <LoginButton />
          </Box>
          <Box
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <Footer />
          </Box>
        </Container>
      </LoginWrapper>
    </Box>
  );
}

export default Login
