import { LogLevel } from "@azure/msal-browser";
import { Constant } from "./Constants";
import { ArchiveStatus, Priority, SortField } from "dto/crashRequestDto";
import { BucketTableSortField, BucketedCrashTableSortField } from "src/dto/bucketInfoDto";
import { RebucketingStatus, SymbolicationState } from "dto/crashDetailsDto";

export function Log(message: any, level: LogLevel = LogLevel.Info) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    // To Do: Apply log based on level
    console.log(message);
  } else {
    // production code
    return;
  }
}

export function isAdmin(role: number) {
  return role === 1;
}

export function getRoleName(role: number) {
  switch (role) {
    case 1:
      return "Admin";
    case 2:
      return "User";
    default:
      break;
  }
}

export function getSymbolicationState(state: SymbolicationState | undefined) {
  switch (state) {
    case SymbolicationState.NotSymbolicated:
      return "Not Symbolicated";
    case SymbolicationState.InQueue:
      return "In Queue";
    case SymbolicationState.InProgress:
      return "In Progress";
    case SymbolicationState.Completed:
      return "Completed";
    case SymbolicationState.Failed:
      return "Failed";
    default:
      break;
  }
}

export function getRebucketingState(state: RebucketingStatus | undefined) {
  switch (state) {
    case RebucketingStatus.PermanentlyFailed:
      return "Permanently Failed";
    case RebucketingStatus.InQueue:
      return "In Queue";
    case RebucketingStatus.Completed:
      return "Completed";
    case RebucketingStatus.Failed:
      return "Failed";
    default:
      break;
  }
}

export function disableSymbolicationButton(state: SymbolicationState | undefined) {
  if (state === SymbolicationState.InProgress) {
    return true;
  } else {
    return false;
  }
}

export const toTitleCase = (str: string) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    }
  );
}

export const isDotNetPlatform = (str: string | undefined) => {
  return str && str.toLowerCase().includes(Constant.DotNet.toLowerCase());
}

export function priorityToString(priority: Priority) {
  switch (priority) {
    case Priority.Low:
      return "Low";
    case Priority.Medium:
      return "Medium";
    case Priority.High:
      return "High";
    default:
      return "";
  }
}

export function archiveStatusToString(archiveStatus: ArchiveStatus) {
  switch (archiveStatus) {
    case ArchiveStatus.NotArchived:
      return "Not Archived";
    case ArchiveStatus.Archived:
      return "Archived";
    default:
      return "";
  }
}

export function stringToPriority(value: string): Priority {
  let map: { [key: string]: Priority } = {
    "Low": Priority.Low,
    "Medium": Priority.Medium,
    "High": Priority.High
  };
  return map[value];
}

export function stringToSortField(value: string): SortField {
  let sortFieldMap: { [key: string]: SortField } = {
    "id": SortField.Id,
    "exceptionCode": SortField.ExceptionCode,
    "applicationName": SortField.ApplicationName,
    "applicationVersion": SortField.ApplicationVersion,
    "osName": SortField.OSName,
    "osVersion": SortField.OSVersion,
    "osBuildNumber": SortField.OSBuildNumber,
    "architecture": SortField.Architecture,
    "platform": SortField.Platform,
    "countOfSimilarCrash": SortField.CountOfSimilarCrash,
    "timestamp": SortField.Timestamp,
    "priority": SortField.Priority,
    "countOfUniqueUser": SortField.CountOfUniqueUser,
    "lastOccurance": SortField.LastOccurance
  };

  return sortFieldMap[value];
}

export function stringToBucketTableSortField(value: string): BucketTableSortField {
  let sortFieldMap: { [key: string]: BucketTableSortField } = {
    "crashCount": BucketTableSortField.CrashCount,
    "countOfSimilarCrash": BucketTableSortField.CountOfSimilarCrash,
    "countOfUniqueUser": BucketTableSortField.CountOfUniqueUser,
    "bucketId": BucketTableSortField.BucketId,
  };

  return sortFieldMap[value];
}

export function stringToBucketedCrashTableSortField(value: string): BucketedCrashTableSortField {
  let sortFieldMap: { [key: string]: BucketedCrashTableSortField } = {
    "applicationVersion": BucketedCrashTableSortField.ApplicationVersion,
    "crashId": BucketedCrashTableSortField.CrashId,
    "timestamp": BucketedCrashTableSortField.Timestamp
  };

  return sortFieldMap[value];
}