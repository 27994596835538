import { Box, CardMedia, styled } from "@mui/material";

const LogoSignWrapper = styled(Box)(
  () => `
        width: 222px;
        height: 53px;
`
);

function LogoSign() {
  return (
    <LogoSignWrapper>
      <CardMedia
        component="img"
        image="static/images/logo/corsair-logo.svg"
        alt="Corsair"
      />
    </LogoSignWrapper>
  );
}

export default LogoSign;
