import {
  Box,
  Button,
  Card,
  CardContent,
  Grid, Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import { LogLevel } from "@azure/msal-browser";
import ListIcon from "@mui/icons-material/List";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import ContainerWrapper from "components/Container/ContainerWrapper";
import PageTitleWrapper from "components/PageTitleWrapper";
import SymbolicationInfoTabs from "pages/symbolicationManager/components/SymbolicationInfoTabs";
import PageHeader from "pages/symbolicationManager/components/PageHeader";
import { useContext, useEffect, useState } from "react";
import { clearSymbolicationQueue, getSymbolicationSummary, putAllToSymbolicationQueue, clearStuckCrashesFromQueue } from "services/symbolicationServices";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import dialogContext, { DialogType } from "utilities/dialogContext";
import { Log } from "utilities/helper";
import { CleaningServices, SelectAll } from "@mui/icons-material";
import { Messages } from "utilities/Messages";
import { Constant } from "src/utilities/Constants";

interface SymbolicationSummary {
  highPriorityInProgressCount: number;
  mediumPriorityInProgressCount: number;
  lowPriorityInProgressCount: number;
  highPriorityInQueueCount: number;
  mediumPriorityInQueueCount: number;
  lowPriorityInQueueCount: number;
  unsymbolicatedCrashCount: number;
}

function SymbolicationManager() {
  var { setDialogType, setShowDialog, setTitle, setMessage, setOnClickOk, setOnClickCancel } =
    useContext(dialogContext);

  const auth = useAppSelector(selectAuth);
  const [symbolicationSummary, setSymbolicationSummary] = useState<SymbolicationSummary>();
  const [disableClearQueueButton, setDisableClearQueueButton] = useState<boolean>(false);
  const [disableSymbolicateAllButton, setDisableSymbolicateAllButton] = useState<boolean>(false);

  const loadSymbolicationSummary = () => {
    getSymbolicationSummary(auth.authToken).then(
      (response) => {
        if (response[0]) {
          var result = response[0];
          if (result.success) {
            setSymbolicationSummary(result.data);
          }
        } else {
          var error = response[1];
          Log(error, LogLevel.Error);
        }
      }
    );
  }

  useEffect(() => {
    loadSymbolicationSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const clearQueue = () => {
    setTitle("Alert!");
    setMessage("Symbolication Queue will be cleared. Are you sure?");
    setDialogType(DialogType.CONFIRMATION);
    setOnClickOk(() => () => {
      setShowDialog(false);
      setMessage("This operation may take a bit time. Please come back later");
      setDialogType(DialogType.MESSAGE);
      setShowDialog(true);
      setOnClickOk(() => () => {
        setShowDialog(false)
        setDisableClearQueueButton(true);
        setTimeout(() => {
          setDisableClearQueueButton(false);
        }, Constant.MaxDisablePeriodInMSForClearButtons);
        clearSymbolicationQueue(auth.authToken).then(
          (response) => {
            if (response[0]) {
              setDisableClearQueueButton(false);
              var title;
              var result = response[0];
              if (result.success) {
                title = "Success";
                loadSymbolicationSummary();
                setMessage(result.message);
              }
              else {
                title = "Error";
                setMessage("Something went wrong. Please try again later");
              }
              setTitle(title);
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            } else {
              var error = response[1];
              Log(error, LogLevel.Error);
              setTitle("Error");
              setMessage("Something went wrong. Please try again later");
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            }
            setShowDialog(true);
          }
        );
      }
      );
    });
    setOnClickCancel(() => () => setShowDialog(false));
    setShowDialog(true);
  }

  const confirmClearStuckCrashes = () => {
    setTitle("Alert!");
    setMessage(Messages.ClearStuckCrashesConfirmation);
    setDialogType(DialogType.CONFIRMATION);
    setOnClickOk(() => () => {
      setShowDialog(false);
      setMessage(Messages.LongOperation);
      setDialogType(DialogType.MESSAGE);
      setShowDialog(true);
      setOnClickOk(() => () => {
        setShowDialog(false)
        setDisableClearQueueButton(true);
        setTimeout(() => {
          setDisableClearQueueButton(false);
        }, Constant.MaxDisablePeriodInMSForClearButtons);
        clearStuckCrashes();
      });
    });
    setOnClickCancel(() => () => setShowDialog(false));
    setShowDialog(true);
  }

  const clearStuckCrashes = () => {
    clearStuckCrashesFromQueue(auth.authToken).then(
      (response) => {
        if (response[0]) {
          setDisableClearQueueButton(false);
          var title;
          var result = response[0];
          if (result.success) {
            title = "Success";
            loadSymbolicationSummary();
            setMessage(result.message);
          }
          else {
            title = "Error";
            setMessage(Messages.SomethingWrong);
          }
          setTitle(title);
          setDialogType(DialogType.MESSAGE);
          setOnClickOk(() => () => setShowDialog(false));
        } else {
          var error = response[1];
          Log(error, LogLevel.Error);
          setTitle("Error");
          setMessage(Messages.SomethingWrong);
          setDialogType(DialogType.MESSAGE);
          setOnClickOk(() => () => setShowDialog(false));
        }
        setShowDialog(true);
      }
    );
  }

  const symbolicateAll = () => {
    setTitle("Alert!");
    setMessage("All unsymbolicated crashes will be symbolicated. Are you sure?");
    setDialogType(DialogType.CONFIRMATION);
    setOnClickOk(() => () => {
      setShowDialog(false);
      setMessage("This operation may take a bit time. Please come back later");
      setDialogType(DialogType.MESSAGE);
      setShowDialog(true);
      setOnClickOk(() => () => {
        setShowDialog(false)
        setDisableSymbolicateAllButton(true);
        setTimeout(() => {
          setDisableSymbolicateAllButton(false);
        }, 30000);
        putAllToSymbolicationQueue(auth.authToken).then(
          (response) => {
            if (response[0]) {
              setDisableSymbolicateAllButton(false);
              var title;
              var result = response[0];
              if (result.success) {
                title = "Success";
                loadSymbolicationSummary();
                setMessage(result.message);
              }
              else {
                title = "Error";
                setMessage("Something went wrong. Please try again later");
              }
              setTitle(title);
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            } else {
              var error = response[1];
              Log(error, LogLevel.Error);
              setTitle("Error");
              setMessage("Something went wrong. Please try again later");
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            }
            setShowDialog(true);
          }
        );
      }
      );
    });
    setOnClickCancel(() => () => setShowDialog(false));
    setShowDialog(true);
  }

  return (
    <>
      <Helmet>
        <title>Symbolication Manager</title>
      </Helmet>

      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <ContainerWrapper>
        <Grid container spacing={1} justifyContent={"center"}>
        <Grid xs={6} sm={6} md={3} item>
            <Card
              sx={{
                px: 1,
                height: "100%",
              }}
            >
              <CardContent>
                <SelectAll color="primary" fontSize="large" />
                <Typography
                  sx={{
                    pt: 1,
                  }}
                  variant="h5"
                >
                  Unsymbolicated Crashes: {symbolicationSummary?.unsymbolicatedCrashCount}
                </Typography>
                <Box
                  sx={{
                    justifySelf: "flex-end",
                    pt: 3,
                  }}
                >
                  <Button
                    size="large"
                    color="error"
                    variant="contained"
                    onClick={symbolicateAll}
                    disabled={disableSymbolicateAllButton}
                  >
                    Symbolicate All
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={6} sm={6} md={3} item>
            <Card
              sx={{
                px: 1,
                height: "100%",
              }}
            >
              <CardContent>
                <ModelTrainingIcon color="primary" fontSize="large" />
                <Typography
                  sx={{
                    pt: 1,
                  }}
                  variant="h5"
                >
                  Symbolication In Progress : {symbolicationSummary?.highPriorityInProgressCount! + symbolicationSummary?.mediumPriorityInProgressCount! + symbolicationSummary?.lowPriorityInProgressCount!}
                </Typography>
                <Box
                  sx={{
                    justifySelf: "flex-end",
                    pt: 2,
                  }}
                >
                  <Typography variant="body1" gutterBottom noWrap>
                    High Priority: {symbolicationSummary?.highPriorityInProgressCount}
                  </Typography>
                  <Typography variant="body1" gutterBottom noWrap>
                    Medium Priority: {symbolicationSummary?.mediumPriorityInProgressCount}
                  </Typography>
                  <Typography variant="body1" gutterBottom noWrap>
                    Low Priority: {symbolicationSummary?.lowPriorityInProgressCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={6} sm={6} md={3} item>
            <Card
              sx={{
                px: 1,
                height: "100%",
              }}
            >
              <CardContent>
                <ListIcon color="primary" fontSize="large" />
                <Typography
                  sx={{
                    pt: 1,
                  }}
                  variant="h5"
                >
                  Symbolication In Queue: {symbolicationSummary?.highPriorityInQueueCount! + symbolicationSummary?.mediumPriorityInQueueCount! + symbolicationSummary?.lowPriorityInQueueCount!}
                </Typography>
                <Box
                  sx={{
                    justifySelf: "flex-end",
                    pt: 2,
                  }}
                >
                  <Typography variant="body1" gutterBottom noWrap>
                    High Priority: {symbolicationSummary?.highPriorityInQueueCount}
                  </Typography>
                  <Typography variant="body1" gutterBottom noWrap>
                    Medium Priority: {symbolicationSummary?.mediumPriorityInQueueCount}
                  </Typography>
                  <Typography variant="body1" gutterBottom noWrap>
                    Low Priority: {symbolicationSummary?.lowPriorityInQueueCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={6} sm={6} md={3} item>
            <Card
              sx={{
                px: 1,
                height: "100%",
              }}
            >
              <CardContent>
                <CleaningServices color="primary" fontSize="large" />

                <Typography
                  sx={{
                    pt: 1,
                  }}
                  variant="h5"
                >
                  Clear The Symbolication Queue
                </Typography>
                <Box
                  sx={{
                    justifySelf: "flex-end",
                    pt: 2,
                  }}
                >
                  <Button
                    size="large"
                    color="error"
                    variant="contained"
                    onClick={clearQueue}
                    disabled={disableClearQueueButton}
                  >
                    Clear Queue
                  </Button>

                  <Button
                    size="large"
                    color="info"
                    variant="contained"
                    onClick={confirmClearStuckCrashes}
                    disabled={disableClearQueueButton}
                    sx={{mt: 1}}
                  >
                    Clear Stuck Crashes
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContainerWrapper >
      <SymbolicationInfoTabs/>
    </>
  );
}

export default SymbolicationManager;
