import { useSnackbar } from 'notistack';
import { selectAuth } from 'slices/authSlice';
import { useAppSelector } from 'store/hooks';
import { Button } from '@mui/material';
import dialogContext from 'src/utilities/dialogContext';
import { useContext } from 'react';

interface FailedRebucketingActionInterface {
  id: number,
  removeFailedRebucketing: (id: number) => void,
  reRebucket: (id: number) => void
}

export default function FailedRebucketingAction(props: FailedRebucketingActionInterface) {
  var {
    setDialogType,
    setShowDialog,
    setTitle,
    setMessage,
    setOnClickOk,
    setOnClickCancel,
  } = useContext(dialogContext);
  
  return (
    <>
      <Button
        variant="contained"
        color="info"
        sx={{ mr: 1 }}
        onClick={() => props.removeFailedRebucketing(props.id)}
      >
        Remove
      </Button>
      <Button
        variant="contained"
        color="info"
        sx={{ mr: 1 }}
        onClick={() => props.reRebucket(props.id)}
      >
        Try again
      </Button>
    </>
  );
}