import { useRoutes } from 'react-router-dom';
import router from 'routes/router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';


function Home() {
  const content = useRoutes(router);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      {content}
    </LocalizationProvider>
  );
}
export default Home;
