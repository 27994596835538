import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import {
  alpha,
  Box,
  ButtonBase,
  Divider,
  Drawer,
  IconButton,
  lighten,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import Logo from "components/Logo";
import HeaderMenu from "layouts/Layout/Header/Menu";
import Sidebar from "layouts/Layout/Header/Sidebar";
import HeaderUserbox from "layouts/Layout/Header/Userbox";
import NotificationPanel from "components/Notification/NotificationPanel";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(
          theme.header.background ? theme.header.background.toString() : "",
          0.95
        )};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const theme = useTheme();
  const [sidebar, setSidebar] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === "dark"
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
      }}
    >
      {matches ? (
        <Box sx={{ display: "flex" }}>
          <Stack
            direction="row"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Logo />
            <HeaderMenu />
          </Stack>
        </Box>
      ) : (
        <Box>
          <IconButton color="primary" onClick={() => setSidebar(!sidebar)}>
            {!sidebar ? (
              <MenuTwoToneIcon fontSize="small" />
            ) : (
              <CloseTwoToneIcon fontSize="small" />
            )}
          </IconButton>
          <Drawer
            anchor="left"
            open={sidebar}
            onClose={() => setSidebar(false)}
          >
            <Box p={2} minWidth={200} role="presentation">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ButtonBase onClick={() => setSidebar(false)}>
                  <Logo />
                </ButtonBase>
                <IconButton color="primary" onClick={() => setSidebar(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Divider
                sx={{
                  mt: theme.spacing(3),
                  mx: theme.spacing(2),
                  background: theme.colors.alpha.trueWhite[10],
                }}
              />
              <Sidebar setSidebar={setSidebar} />
            </Box>
          </Drawer>
          <Divider orientation="vertical" flexItem />
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <NotificationPanel/>
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
