import { createContext } from "react";

export enum DialogType {
  MESSAGE = 1,
  CONFIRMATION = 2,
}
const dialogContext = createContext({
  dialogType: DialogType.MESSAGE,
  setDialogType: (dialogType: DialogType) => {
    return;
  },
  showDialog: false,
  setShowDialog: (showState: boolean) => {
    return;
  },
  title: "",
  setTitle: (title: string) => {
    return;
  },
  message: "",
  setMessage: (message: string) => {
    return;
  },
  verificationText: "",
  setVerificationText: (message: string) => {
    return;
  },
  onClickOk: () => {},
  setOnClickOk: (func: () => void) => {},
  onClickCancel: () => {},
  setOnClickCancel: (func: () => void) => {},
});
export default dialogContext;
