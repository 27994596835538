import { createSlice } from "@reduxjs/toolkit";

import User from "dto/userDto";
import { RootState } from "store/store";

export interface userType {
  List: User[];
}

const initialState: userType = {
  List: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, Action: { payload: User[]; type: string }) => {
      state.List = Action.payload.map((item) => {
        return { ...item };
      });
    },
    addUser: (state, Action: { payload: User; type: string }) => {
      var exist = state.List.find((u) => u.id === Action.payload.id);
      if (!exist) {
        state.List = [Action.payload, ...state.List];
      }
    },
    updateUser: (state, Action: { payload: User; type: string }) => {
      state.List = state.List.map((u) =>
        u.id !== Action.payload.id ? u : Action.payload
      );
    },
    deleteUser: (state, Action: { payload: Number; type: string }) => {
      state.List = state.List.filter((item) => item.id !== Action.payload);
    },
  },
});

export const { setUserData, addUser, deleteUser, updateUser } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
