import { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { ITableProps, Table, kaReducer } from "ka-table";
import { ChildComponents } from "ka-table/models";
import { DataType, SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import "components/Crash/CrashTable.scss";
import failedRebucketingInfo from "src/dto/failedRebucketingInfoDto";
import { getRebucketingState, getSymbolicationState } from "utilities/helper";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import { RebucketingStatus, SymbolicationState } from "src/dto/crashDetailsDto";
import { LogLevel } from "@azure/msal-browser";
import { Log } from "utilities/helper";
import ViewDetailsButton from "components/CrashDetails/ViewDetailsButton";
import TableFooter from "components/TableFooter";
import { clearFailedRebucketing, getFailedRebucketingInfo, reRebucketCrash } from "src/services/bucketServices";
import FailedRebucketingAction from "src/components/ReBucket/FailedRebucketingActionButton";
import dialogContext, { DialogType } from "src/utilities/dialogContext";

interface rebucketingInfoTableProps {
  rebucketingState: RebucketingStatus,
  loadRebucketSummary: () => void
};

interface dataArrayType {
  id: number;
  rebucketingStatus: string | undefined;
  failureMsg: string;
}

function getTableOption(dataArray: dataArrayType[]) {
  let tableOption: ITableProps = {
    columns: [
      {
        key: "id",
        title: "Id",
        colGroup: { style: { minWidth: 25, maxWidth: 50 } },
        style: { textAlign: "center" },
        dataType: DataType.Number,
        sortDirection: SortDirection.Descend
      },
      {
        key: "failureMsg",
        title: "Failure Reason",
        colGroup: { style: { minWidth: 100, maxWidth: 300 } },
        style: { textAlign: "center", wordWrap: "break-word" },
        dataType: DataType.String,
      },
      {
        key: "action",
        title: "Action",
        colGroup: { style: { width: 210 } },
        style: { textAlign: "center" },
        dataType: DataType.String,
      }
    ],
    data: dataArray,
    rowKeyField: "id",
  };

  return tableOption;
}

function mapFailedRebucketingInfosToDataArray(failedRebucketingInfos: failedRebucketingInfo[]) {

  if(failedRebucketingInfos === undefined || failedRebucketingInfos === null) {
    return [];
  }

  return failedRebucketingInfos.map((ListItem: failedRebucketingInfo) => ({
    id: ListItem.id,
    rebucketingStatus: getRebucketingState(ListItem.rebucketingStatus),
    failureMsg: ListItem.failureMsg
  }));
}


function FailedRebucketingInfoTable(rebucketingState: rebucketingInfoTableProps) {
  const auth = useAppSelector(selectAuth);
  const [failedRebucketingInfos, setFailedRebucketingInfos] = useState<failedRebucketingInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  let dataArray = mapFailedRebucketingInfosToDataArray(failedRebucketingInfos);
  let tableOption: ITableProps = getTableOption(dataArray);
  const [option, changeOptions] = useState(tableOption);
  const dispatch: DispatchFunc = (action) => {
    changeOptions((prevState: ITableProps) => kaReducer(prevState, action));
  };
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [dataLength, setDataLength] = useState<number>(0);
  
  var {
    setDialogType,
    setShowDialog,
    setTitle,
    setMessage,
    setOnClickOk,
    setOnClickCancel,
  } = useContext(dialogContext);

  const loadFailedRebucketingInfos = () => {
    setIsLoading(true);
    getFailedRebucketingInfo(auth.authToken, page + 1, limit).then(
      (response) => {
        if (response[0]) {
          var result = response[0];
          if (result.success) {
            setDataLength(result.totalCount);
            setFailedRebucketingInfos(result.data);
            updateTableData();
          }
        } else {
          var error = response[1];
          Log(error, LogLevel.Error);
        }
      }
    ).finally(() => setIsLoading(false));
  }

  const reRebucket = (id: number) => {
    setIsLoading(true);
    var message = "This crash will be added to Rebucket task queue again. Are you sure?";
    setTitle("Rebucketing");
    setMessage(message);
    setDialogType(DialogType.CONFIRMATION);
    setOnClickOk(() => () => {
      reRebucketCrash(auth.authToken, id).then(
        (response) => {
          if (response[0]) {
            var result = response[0];
            if (result.success) {
              // Re-rebucket the crash
              setFailedRebucketingInfos(failedRebucketingInfos.filter(r => r.id !== id));
              setDataLength(failedRebucketingInfos.filter(r => r.id !== id).length);
              updateTableData();
              setTitle("Success");
              setMessage(
                "The crash is placed in Rebucket task queue."
              );
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => {
                setShowDialog(false);
                rebucketingState.loadRebucketSummary();
              });
            }
            else {
              setTitle("Error");
              setMessage("Something went wrong. Please try again later");
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            }
          } else {
            var error = response[1];
            Log(error, LogLevel.Error);
          }
          setShowDialog(true);
        }).finally(() => setIsLoading(false));
      setShowDialog(false);
    });
    setOnClickCancel(() => () => {
      setShowDialog(false);
    });
    setShowDialog(true);
  }

  const removeFailedRebucketing = (id: number) => {
    setIsLoading(true);
    var message = "This crash will be marked as unable to rebucket and be removed from this list. Are you sure?";
    setTitle("Alert!");
    setMessage(message);
    setDialogType(DialogType.CONFIRMATION);
    setOnClickOk(() => () => {
      clearFailedRebucketing(auth.authToken, id).then(
        (response) => {
          if (response[0]) {
            var result = response[0];
            if (result.success) {
              // Remove the crash
              setFailedRebucketingInfos(failedRebucketingInfos.filter(r => r.id !== id));
              setDataLength(failedRebucketingInfos.filter(r => r.id !== id).length);
              updateTableData();
              setTitle("Success");
              setMessage(
                "The crash is marked as Permanently failed and can never be rebucketed again."
              );
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => {
                setShowDialog(false);
                rebucketingState.loadRebucketSummary();
              });
            }
            else {
              setTitle("Error");
              setMessage("Something went wrong. Please try again later");
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
            }
          } else {
            var error = response[1];
            Log(error, LogLevel.Error);
          }
          setShowDialog(true);
        }).finally(() => setIsLoading(false));
        setShowDialog(false);
    });
    setOnClickCancel(() => () => {
      setShowDialog(false);
    });
    setShowDialog(true);
  }

  useEffect(() => {
    loadFailedRebucketingInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, page, limit]);

  const ChildComponents: ChildComponents = {
    noDataRow: {
      content: () => <Box padding={"3%"} sx={{ alignItems: "center", justifyContent: "center" }}>{isLoading ? "" : "No Data Found"}</Box>
    },

    pagingIndex: {
      elementAttributes: ({ isActive }) => ({
        className: `page-item waves-effect ${isActive ? "active" : ""}`,
      }),
    },

    pagingSize: {
      elementAttributes: ({ value }) => ({
        className: `page-item waves-effect ${value === option.paging?.pageSize ? "active" : ""
          }`,
      }),
    },

    pagingSizes: {
      elementAttributes: () => ({
        className: "pagination",
      }),
    },

    pagingPages: {
      elementAttributes: () => ({
        className: "pagination",
      }),
    },

    cell: {
      content: (props) => {
        switch (props.column.key) {
          case "id":
            return <ViewDetailsButton id={props.rowData["id"]} />;
          case "isHealthy":
            return props.rowData["isHealthy"] ? "🟢" : "🔴";
          case "action":
            return FailedRebucketingAction({ id: props.rowData["id"], removeFailedRebucketing, reRebucket });
        }
      },
    },
  }

  const updateTableData = () => {
    dataArray = mapFailedRebucketingInfosToDataArray(failedRebucketingInfos);
  };


  return (
    <>
      <div className="ka-table-crash">
        <Table
          {...option}
          loading={{
            enabled: isLoading,
          }}
          childComponents={ChildComponents}
          dispatch={dispatch}
          sortingMode={SortingMode.SingleRemote}
          data={dataArray}
        />
        <TableFooter
          page={page}
          limit={limit}
          dataLength={dataLength}
          setPage={setPage}
          setLimit={setLimit}
        />
      </div>
    </>
  )
}

export default FailedRebucketingInfoTable;
