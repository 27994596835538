import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Home from "pages/home";
import Login from "pages/login";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import Blank from "src/pages/blank";

const PageRoutes = () => {
  const isLoggedIn = useAppSelector(selectAuth).isLoggedIn;

  return (
    <div>
      <Routes>
        <Route
          path="*"
          element={isLoggedIn ? <Home /> : <Navigate to="login" replace />}
        />
        <Route path="login" element={<Login />} />
        <Route path="blank" element={<Blank />} />
      </Routes>
    </div>
  );
};

export default PageRoutes;
