import { Grid, Typography } from "@mui/material";

function PageHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center" mt={0.2}>
      <Grid item>
        <Typography variant="h2" component="h2" gutterBottom>
          Symbolication Manager
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
