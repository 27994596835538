import { Link } from "@mui/material";

type ViewDetailsButtonProps = {
  id: number;
};
export default function ViewDetailsButton({ id }: ViewDetailsButtonProps) {
  return (
    <Link href={window.location.origin + "/Crash/" + id} underline="hover">
      {id}
    </Link>
  );
}
