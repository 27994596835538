import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ITableProps, Table, kaReducer } from "ka-table";
import { ChildComponents } from "ka-table/models";
import { DataType, SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import "components/Crash/CrashTable.scss";
import symbolicationInfo from "src/dto/symbolicationInfoDto";
import { getSymbolicationState } from "utilities/helper";
import { getSymbolicationInfo } from "services/symbolicationServices";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import { SymbolicationState } from "src/dto/crashDetailsDto";
import { LogLevel } from "@azure/msal-browser";
import { Log } from "utilities/helper";
import ViewDetailsButton from "components/CrashDetails/ViewDetailsButton";
import TableFooter from "components/TableFooter";

type symbolicationInfoTableProps = {
  symbolicationState: SymbolicationState
};

interface dataArrayType {
  id: number;
  elapsedTime: string;
  symbolicationState: string | undefined;
  failureMsg: string;
}

function getTableOption(dataArray: dataArrayType[], symbolicationState: SymbolicationState) {
  let tableOption: ITableProps = {
    columns: [
      {
        key: "id",
        title: "Id",
        colGroup: { style: { minWidth: 25, maxWidth: 50 } },
        style: { textAlign: "center" },
        dataType: DataType.Number,
        sortDirection: SortDirection.Descend
      },
      {
        key: symbolicationState === SymbolicationState.Failed ? "failureMsg" : "elapsedTime",
        title: symbolicationState === SymbolicationState.InQueue ? "Waiting Time" :
          (symbolicationState === SymbolicationState.InProgress ? "Elapsed Time" : "Failure Reason"),
        colGroup: { style: { minWidth: 100, maxWidth: 300 } },
        style: { textAlign: "center", wordWrap: "break-word" },
        dataType: DataType.String,
      },
      {
        key: symbolicationState === SymbolicationState.Failed ? "retryCount" : "symbolicationState",
        title: symbolicationState === SymbolicationState.Failed ? "Retry Count" : "Symbolication State",
        colGroup: { style: { minWidth: 100, maxWidth: 300 } },
        style: { textAlign: "center", wordWrap: "break-word" },
        dataType: symbolicationState === SymbolicationState.Failed ? DataType.Number : DataType.String,
      },
    ],
    data: dataArray,
    rowKeyField: "id",
  };

  if (symbolicationState === SymbolicationState.InProgress) {
    tableOption.columns.push({
      key: "isHealthy",
      title: "Healthy",
      colGroup: { style: { minWidth: 25, maxWidth: 50 } },
      style: { textAlign: "center", wordWrap: "break-word" },
      dataType: DataType.Boolean,
    });
  }

  return tableOption;
}

function mapSymbolicationInfosToDataArray(symbolicationInfos: symbolicationInfo[]) {
  return symbolicationInfos.map((ListItem: symbolicationInfo) => ({
    id: ListItem.id,
    elapsedTime: ListItem.elapsedTimeInSymbolicationState,
    symbolicationState: getSymbolicationState(ListItem.symbolicationState),
    failureMsg: ListItem.failureMsg,
    retryCount: ListItem.retryCount,
    isHealthy: ListItem.isHealthy
  }));
}


function SymbolicationInfoTable({ symbolicationState }: symbolicationInfoTableProps) {
  const auth = useAppSelector(selectAuth);
  const [symbolicationInfos, setSymbolicationInfos] = useState<symbolicationInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  let dataArray = mapSymbolicationInfosToDataArray(symbolicationInfos);
  let tableOption: ITableProps = getTableOption(dataArray, symbolicationState);
  const [option, changeOptions] = useState(tableOption);
  const dispatch: DispatchFunc = (action) => {
    changeOptions((prevState: ITableProps) => kaReducer(prevState, action));
  };
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [dataLength, setDataLength] = useState<number>(0);

  const loadSymbolicationInfos = () => {
    getSymbolicationInfo(auth.authToken, symbolicationState, page + 1, limit).then(
      (response) => {
        if (response[0]) {
          var result = response[0];
          if (result.success) {
            setDataLength(result.totalCount);
            setSymbolicationInfos(result.data);
            updateTableData();
          }
        } else {
          var error = response[1];
          Log(error, LogLevel.Error);
        }
      }
    )
  }

  useEffect(() => {
    setIsLoading(true);
    loadSymbolicationInfos();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, page, limit]);

  const ChildComponents: ChildComponents = {
    noDataRow: {
      content: () => <Box padding={"3%"} sx={{ alignItems: "center", justifyContent: "center" }}>{isLoading ? "" : "No Data Found"}</Box>
    },

    pagingIndex: {
      elementAttributes: ({ isActive }) => ({
        className: `page-item waves-effect ${isActive ? "active" : ""}`,
      }),
    },

    pagingSize: {
      elementAttributes: ({ value }) => ({
        className: `page-item waves-effect ${value === option.paging?.pageSize ? "active" : ""
          }`,
      }),
    },

    pagingSizes: {
      elementAttributes: () => ({
        className: "pagination",
      }),
    },

    pagingPages: {
      elementAttributes: () => ({
        className: "pagination",
      }),
    },

    cell: {
      content: (props) => {
        switch (props.column.key) {
          case "id":
            return <ViewDetailsButton id={props.rowData["id"]} />;
          case "isHealthy":
            return props.rowData["isHealthy"] ? "🟢" : "🔴";
        }
      },
    },
  }

  const updateTableData = () => {
    dataArray = mapSymbolicationInfosToDataArray(symbolicationInfos);
  };


  return (
    <>
      <div className="ka-table-crash">
        <Table
          {...option}
          loading={{
            enabled: isLoading,
          }}
          childComponents={ChildComponents}
          dispatch={dispatch}
          sortingMode={SortingMode.SingleRemote}
          data={dataArray}
        />
        <TableFooter
          page={page}
          limit={limit}
          dataLength={dataLength}
          setPage={setPage}
          setLimit={setLimit}
        />
      </div>
    </>
  )
}

export default SymbolicationInfoTable;
