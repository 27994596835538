import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface selectAppVerType {
  selectedApps: string[];
  selectedVersions: string[];
  checkedApps: string[];
  selectAppOption: string;
  checkedVersions: string[];
  selectVersionOption: string;
}

const initialState: selectAppVerType = {
  selectedApps: ["All Applications"],
  selectedVersions: ["All Versions"],
  checkedApps: [],
  selectAppOption: "Select All",
  checkedVersions: [],
  selectVersionOption: "Select All",
};

export const appVerSlice = createSlice({
  name: "AppVer",
  initialState,
  reducers: {
    setSelectedApps: (state, Action: { payload: string[]; type: string }) => {
      state.selectedApps = Action.payload;
    },
    setSelectedVersions: (
      state,
      Action: { payload: string[]; type: string }
    ) => {
      state.selectedVersions = Action.payload;
    },
    setCheckedApps: (state, Action: { payload: string[]; type: string }) => {
      state.checkedApps = Action.payload;
    },
    setSelectAppOption: (state, Action: { payload: string; type: string }) => {
      state.selectAppOption = Action.payload;
    },
    setCheckedVersions: (
      state,
      Action: { payload: string[]; type: string }
    ) => {
      state.checkedVersions = Action.payload;
    },
    setSelectVersionOption: (
      state,
      Action: { payload: string; type: string }
    ) => {
      state.selectVersionOption = Action.payload;
    },
  },
});

export const {
  setSelectedApps,
  setSelectedVersions,
  setCheckedApps,
  setCheckedVersions,
  setSelectAppOption,
  setSelectVersionOption,
} = appVerSlice.actions;

export const selectAppVer = (state: RootState) => state.appVer;

export default appVerSlice.reducer;
