import { LogLevel } from "@azure/msal-browser";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet-async";
import dialogContext, { DialogType } from "utilities/dialogContext";

import PageTitleWrapper from "components/PageTitleWrapper";
import PageHeader from "pages/defectTracker/components/PageHeader";
import {
  createDefectTracker,
  getDefectTracker,
  updateDefectTracker,
} from "services/defectTrackerService";
import { selectAuth } from "slices/authSlice";
import { useAppSelector } from "store/hooks";
import { Log } from "utilities/helper";

function DefectTrackerUI() {
  var { setDialogType, setShowDialog, setTitle, setMessage, setOnClickOk } =
    useContext(dialogContext);

  const auth = useAppSelector(selectAuth);
  const [serverUrl, setServerUrl] = useState("");
  const [username, setUsername] = useState("");
  const [apiToken, setApiToken] = useState("");

  const [newServerUrl, setNewServerUrl] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newApiToken, setNewApiToken] = useState("");

  const [tracker, setTracker] = useState(1);
  const [updateTracker, setUpdateTracker] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleTrackerChange = (event: SelectChangeEvent) => {
    setTracker(Number(event.target.value));
  };

  const handleSubmit = () => {
    var data = {
      trackerType: tracker,
      credential: JSON.stringify({
        ServerURL: newServerUrl,
        Username: newUsername,
        APIToken: newApiToken,
      }),
    };
    if (updateTracker) {
      updateDefectTracker(auth.authToken, Number(tracker), data).then(
        (response) => {
          if (response[0]) {
            var result = response[0];
            if (result.success) {
              setServerUrl(newServerUrl);
              setUsername(newUsername);
              setApiToken(newApiToken);
            } else {
              setTitle("Error");
              setMessage(result.message);
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
              setShowDialog(true);
            }
          } else {
            var error = response[1];
            Log(error, LogLevel.Error);
          }
        }
      );
    } else {
      console.log(data);
      createDefectTracker(auth.authToken, Number(tracker), data).then(
        (response) => {
          if (response[0]) {
            var result = response[0];
            if (result.success) {
              setServerUrl(newServerUrl);
              setUsername(newUsername);
              setApiToken(newApiToken);
            } else {
              setTitle("Error");
              setMessage(result.message);
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
              setShowDialog(true);
            }
          } else {
            var error = response[1];
            Log(error, LogLevel.Error);
          }
        }
      );
    }
  };

  useEffect(() => {
    getDefectTracker(auth.authToken, Number(tracker)).then((response) => {
      if (response[0]) {
        var result = response[0];
        if (result.success) {
          var credential = JSON.parse(result.data["credential"]);

          setServerUrl(credential["ServerURL"]);
          setUsername(credential["Username"]);
          setApiToken(credential["APIToken"]);

          setNewServerUrl(credential["ServerURL"]);
          setNewUsername(credential["Username"]);
          setNewApiToken(credential["APIToken"]);

          setUpdateTracker(true);
        } else {
          setServerUrl("");
          setUsername("");
          setApiToken("");

          setNewServerUrl("");
          setNewUsername("");
          setNewApiToken("");
        }
      } else {
        var error = response[1];
        Log(error, LogLevel.Error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, tracker]);

  return (
    <>
      <Helmet>
        <title>Defect Tracker</title>
      </Helmet>

      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          spacing={1}
        >
          <Grid item container sm={12} md={6} p={2}>
            <Card sx={{ p: 2, width: "100%" }}>
              <Box sx={{ pb: 2 }}>
                <Typography>
                  <h3>Integrate your defect tracking system</h3>
                  <Divider sx={{ mb: 1 }} />
                </Typography>
              </Box>
              <Grid
                item
                container
                display="flex"
                direction="column"
                md={12}
                sm={12}
                spacing={1}
              >
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Defect Tracker
                    </InputLabel>
                    <Select
                      value={tracker.toString()}
                      label="Defect Tracker"
                      onChange={handleTrackerChange}
                      fullWidth
                    >
                      <MenuItem value={1}>Jira</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item container display="row" md={6} sm={12} xs={12}>
                  <Grid
                    item
                    container
                    md={6}
                    sm={12}
                    xs={12}
                    alignContent="center"
                    pb={1}
                  >
                    <Typography variant="h5">Server URL:</Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      label="Server URL (e.g. https://my.atlassian.net)"
                      value={newServerUrl}
                      onChange={(e) => setNewServerUrl(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item container display="row" md={6} sm={12} xs={12}>
                  <Grid
                    item
                    container
                    md={6}
                    sm={12}
                    xs={12}
                    alignContent="center"
                    pb={1}
                  >
                    <Typography variant="h5">Username:</Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      label="Username"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item container display="row" pt={1}>
                  <Grid
                    item
                    container
                    md={6}
                    sm={12}
                    xs={12}
                    alignContent="center"
                    pt={1}
                    pb={1}
                  >
                    <Typography variant="h5">API Token:</Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <OutlinedInput
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={newApiToken}
                      onChange={(e) => setNewApiToken(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item container justifyContent="flex-end">
                  <Button
                    style={{ marginTop: 16 }}
                    variant="contained"
                    color="primary"
                    disabled={
                      serverUrl === newServerUrl &&
                      username === newUsername &&
                      apiToken === newApiToken
                    }
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default DefectTrackerUI;
