export default interface CrashDetailsDto {
  id: number;
  applicationId: number;
  applicationName: string;
  parentCrashId: number;
  installationIdentifier: string;
  applicationVersion: string;
  platform: string;
  architecture: string;
  osName: string;
  osVersion: string;
  osBuildNumber: string;
  exceptionCode: string;
  stackTrace: string;
  comment: string;
  timestamp: string;
  dumpFileURL: string;
  attachments: string[];
  countOfSimilarCrash: number;
  symbolicationState: SymbolicationState;
  defectTrackerIssueURL: string[];
}

export enum SymbolicationState {
  Failed = -1,
  NotSymbolicated = 0,
  InQueue = 1,
  InProgress = 2,
  Completed = 3
}

export enum RebucketingStatus {
  PermanentlyFailed = -2,
  Failed = -1,
  InQueue = 1,
  Completed = 3
}