import {
    deleteWithAuth,
    getWithAuth,
    putWithAuth
} from "utilities/api";

export async function getSymbolicationSummary(accessToken: string) {
    var url = window.env.baseURL + "symbolication/summary";
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function clearSymbolicationQueue(accessToken: string) {
    var url = window.env.baseURL + "symbolication/clearQueue";
    return deleteWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function getSymbolicationInfo(accessToken: string, 
    state:number,
    currentPageNumber: number = 1,
    itemPerPage: number = 15
) {
    var url = window.env.baseURL + `symbolication/info?state=${state}&currentPageNumber=${currentPageNumber}&itemPerPage=${itemPerPage}`;
    return getWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function putAllToSymbolicationQueue(accessToken: string) {
    var url = window.env.baseURL + "symbolication/all";
    return putWithAuth(url, accessToken).then((response) => {
        return response;
    });
}

export async function clearStuckCrashesFromQueue(accessToken: string) {
    var url = window.env.baseURL + "symbolication/clear-stuck-crashes";
    return deleteWithAuth(url, accessToken).then((response) => {
        return response;
    });
}