import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";
import ThemeProvider from "src/theme/ThemeProvider";

import AlertDialog from "components/AlertDialog";
import { msalConfig } from "configs/authConfig";
import PageRoutes from "routes/index";
import { initializeRuntimeEnv } from "slices/RuntimeEnvSlice";
import { useAppDispatch } from "store/hooks";
import { getWithAuth } from "utilities/api";
import dialogContext, { DialogType } from "utilities/dialogContext";

import "src/App.css";
import { useSelector } from "react-redux";
import { selectHTTPStatus, setStatus } from "slices/httpStatusSlice";

var msalInstance: any = null;

function App() {
  const httpStatus = useSelector(selectHTTPStatus);
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useAppDispatch();

  const [dialogType, setDialogType] = useState(DialogType.MESSAGE);
  const [showDialog, setShowDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [verificationText, setVerificationText] = useState("");
  const [onClickOk, setOnClickOk] = useState<() => void>(() => {});
  const [onClickCancel, setOnClickCancel] = useState<() => void>(() => {});

  const statusCodeToHandle: any = {
    401: {
      title: "Session Expired!",
      message: "You have been logged out. Please Sign in Again",
    },
    500: {
      title: "Error!",
      message: "Please Try Again Later",
    },
  };

  useEffect(() => {
    getWithAuth("/appSettings.json", "").then((response) => {
      if (response[0]) {
        window.env = response[0];

        msalConfig.auth.clientId = window.env.clientId;
        msalConfig.auth.authority = window.env.authority;
        msalConfig.auth.redirectUri = window.env.redirectUri;
        msalInstance = new PublicClientApplication(msalConfig);

        dispatch(initializeRuntimeEnv(response[0]));
        setLoaded(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Executed when httpStatus changes
  // httpStatus changes when axios interceptor catches an error and update redux store
  // update the "statusCodeToHandle" object to set data for relavent status code
  // Shows an alert dialog
  useEffect(() => {
    if (Object.keys(statusCodeToHandle).includes(httpStatus.code.toString())) {
      const onClose = () => {
        dispatch(
          setStatus({
            code: 0,
            message: "",
          })
        );
        setShowDialog(false);
      };

      setTitle(statusCodeToHandle[httpStatus.code].title);
      setMessage(statusCodeToHandle[httpStatus.code].message);
      setDialogType(DialogType.MESSAGE);
      setOnClickOk(() => onClose);
      setOnClickCancel(() => onClose);
      setShowDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpStatus]);

  return (
    <dialogContext.Provider
      value={{
        dialogType,
        setDialogType,
        showDialog,
        setShowDialog,
        title,
        setTitle,
        message,
        setMessage,
        verificationText,
        setVerificationText,
        onClickOk,
        setOnClickOk,
        onClickCancel,
        setOnClickCancel,
      }}
    >
      <div className="App">
        {isLoaded ? (
          <MsalProvider instance={msalInstance}>
            <ThemeProvider>
              <AlertDialog />
              <PageRoutes />
            </ThemeProvider>
          </MsalProvider>
        ) : null}
      </div>
    </dialogContext.Provider>
  );
}
export default App;
