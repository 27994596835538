import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import appReducer from "slices/appSlice";
import authReducer from "slices/authSlice";
import crashReducer from "slices/crashSlice";
import recentCrashReducer from "slices/recentCrashSlice";
import envReducer from "slices/RuntimeEnvSlice";
import appVerReducer from "slices/selectedAppVer";
import userReducer from "slices/userSlice";
import httpStatusReducer from "slices/httpStatusSlice";
import notificationSlice from "src/slices/notificationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    crash: crashReducer,
    app: appReducer,
    user: userReducer,
    recentCrash: recentCrashReducer,
    appVer: appVerReducer,
    runtimeEnv: envReducer,
    httpStatus: httpStatusReducer,
    notification: notificationSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
