import { LogLevel } from "@azure/msal-browser";
import { Box, Button, Dialog, DialogActions, IconButton } from "@mui/material";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { markCrashNotification } from "src/services/notificationService";
import { selectAuth } from "src/slices/authSlice";
import { useAppSelector } from "src/store/hooks";
import { Log } from "src/utilities/helper";
import DialogHeader from "../DialogBox/DialogHeader";
import { MarkCrashNotificationDto } from "src/dto/notificationDto";

type MarkAsReadButtonProps = {
    crashId: number,
    isRead: boolean,
    triggerUpdateData: any
}

export const MarkButton = ({crashId, isRead, triggerUpdateData}: MarkAsReadButtonProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAppSelector(selectAuth);
    const [isReadStatus, setIsReadStatus] = useState(isRead);
    const btnColor = isReadStatus === true ? "secondary" : "primary";
    const readText = isReadStatus === true ? "Unread" : "Read";
    const readIcon = isReadStatus === true ? <MarkEmailReadIcon /> : <MarkEmailUnreadIcon />;

    const mark = () => {
        markCrashNotification(auth.authToken, crashId, !isReadStatus).then((response) => {
            if(response[0] && response[0].success) {
                enqueueSnackbar(`Crash-${crashId} has been marked as ${readText} successfully`, { variant: "success" });
                setIsReadStatus(!isReadStatus);
                triggerUpdateData();
            }
            else {
                var error = response[0] ? response[0].message : response[1];
                Log(error, LogLevel.Error);
                enqueueSnackbar(`Crash-${crashId} could not be marked as ${readText}`, { variant: "error" });
            }
        })
    }

    return (
        <>
            <IconButton color={btnColor} aria-label={readText} onClick={() => mark()}>
                {readIcon}
            </IconButton>
        </>
    );
}