import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Typography } from "@mui/material";

export default function DialogHeader(props: any) {
  return (
    <DialogTitle sx={{ m: 0, p: 2, pb: 2 }}>
      <Typography variant="h4" sx={{ pl: 0 }}>
        {props.title}
      </Typography>
      {props.onClose ? (
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
