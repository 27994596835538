import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import App from "dto/appDto";

interface appType {
  List: App[];
}

const initialState: appType = {
  List: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppData: (state, Action: { payload: App[]; type: string }) => {
      state.List = Action.payload.map((item) => {
        return { ...item };
      });
    },
    addApp: (state, Action: { payload: App; type: string }) => {
      state.List = [...state.List, Action.payload];
    },
    updateApp: (state, Action: { payload: App; type: string }) => {
      state.List = state.List.map((u) =>
        u.id !== Action.payload.id ? u : Action.payload
      );
    },
  },
});

export const { setAppData, addApp, updateApp } = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
