import { AxiosResponse } from "axios";
import { logOut } from "slices/authSlice";
import { setStatus } from "slices/httpStatusSlice";
import { store } from "store/store";

const axios = require("axios").default;

// This interceptor catches any error and update the redux store for httpStatus
// For error code 401 the redux store for authentication is cleared which logs out the user
// Changes is redux store (httpStatus) is captured by App.tsx which shows an alert dialog
axios.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if(error.code === 'ERR_CANCELED') {
      console.log(error.message);
      store.dispatch(
        setStatus({
          code: error.code,
          message: error.message,
        })
      );
    }
    else {
      console.log(error.response.message);
      store.dispatch(
        setStatus({
          code: error.response.status,
          message: error.response.message,
        })
      );
      if (error.response.status === 401) {
        if (store.getState().auth.isLoggedIn) {
          store.dispatch(logOut());
        }
      }
    }

    return Promise.reject(error);
  }
);

export async function postWithAuth(
  url: string,
  accessToken: string,
  params: object = {},
  signal: AbortSignal | null = null
) {
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .post(url, params, {
      headers: headers,
      signal: signal
    })
    .then(function (response: AxiosResponse<Response>) {
      return [response.data, null];
    })
    .catch((error: any) => {
      return [null, error];
    });
}

export async function getWithAuth(url: string, accessToken: string, signal: AbortSignal | null = null) {
  const headers = { Authorization: `Bearer ${accessToken}` };

  return axios
    .get(url, {
      headers: headers,
      signal: signal
    })
    .then(function (response: AxiosResponse<Response>) {
      return [response.data, null];
    })
    .catch((error: any) => {
      return [null, error];
    });
}

export async function deleteWithAuth(url: string, accessToken: string) {
  const headers = { Authorization: `Bearer ${accessToken}` };

  return axios
    .delete(url, {
      headers: headers,
    })
    .then(function (response: AxiosResponse<Response>) {
      return [response.data, null];
    })
    .catch((error: any) => {
      return [null, error];
    });
}

export async function deleteWithAuthHasBody(
  url: string,
  accessToken: string,
  params: object = {}
) {
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .delete(url, { data: params, headers: headers })
    .then(function (response: AxiosResponse<Response>) {
      return [response.data, null];
    })
    .catch((error: any) => {
      return [null, error];
    });
}

export async function putWithAuth(
  url: string,
  accessToken: string,
  params: object = {}
) {
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .put(url, params, {
      headers: headers,
    })
    .then(function (response: AxiosResponse<Response>) {
      return [response.data, null];
    })
    .catch((error: any) => {
      return [null, error];
    });
}
