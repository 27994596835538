import { Box, Card, Container } from "@mui/material";
import React, { useState } from "react";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { RebucketingStatus } from "src/dto/crashDetailsDto";
import FailedRebucketingInfoTable from "./FailedRebucketingInfoTable";

function FailedRebucketingInfoTabs(props: any) {
    const [menuIndex, setMenuIndex] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setMenuIndex(newValue);
    };

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 4, ml: '20%', width: '60%' }}>
                <Card>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={menuIndex}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    boxShadow: "none",
                                    backgroundColor: "white",
                                    borderColor: "lightgrey",
                                    borderBottom: 0,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                },
                            }}
                        >
                            <Tab
                                label={<span style={{ color: "black" }}>Failed Rebucketings</span>}
                                value={0}
                            />
                        </Tabs>
                    </Box>
                    <Box>
                        <Box>
                            <FailedRebucketingInfoTable loadRebucketSummary={props.loadRebucketSummary} rebucketingState={RebucketingStatus.Failed} />
                        </Box>
                    </Box>
                </Card>
            </Container>
        </>
    )
}

export default FailedRebucketingInfoTabs;