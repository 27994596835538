import { Log } from "utilities/helper";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: "",
    authority: "",
    redirectUri: "",
  },
  cache: {
    // cacheLocation: "sessionStorage", // This configures where your cache will be stored
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        Log(message, level);
        console.log(message, level);
      },
    },
  },
};
export const apiRequest = {
  scopes: [""],
};
