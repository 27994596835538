import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import {MarkCrashNotificationDto, NotificationDto} from "dto/notificationDto";

interface notificationSliceType {
  List: NotificationDto[];
}

const initialState: notificationSliceType = {
  List: [],
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, Action: { payload: NotificationDto[]; type: string }) => {
      state.List = Action.payload.map((item) => {
        return { ...item };
      });
    },
    addNotification: (state, Action: { payload: NotificationDto; type: string }) => {
      state.List = [...state.List, Action.payload];
    },
    deleteNotification: (state, Action: { payload: Number; type: string }) => {
      state.List = state.List.filter((item) => item.item.id !== Action.payload);
    },
    markNotification: (state, Action: { payload: MarkCrashNotificationDto; type: string }) => {
      var exist = state.List.find((u) => u.item.crashId === Action.payload.crashId);
      if (exist) {
        exist.isRead = Action.payload.isRead;
      }
    },
    clearNotifications: (state) => {
      state.List = [];
    }
  },
});

export const { setNotifications, addNotification, deleteNotification, clearNotifications, markNotification} = notificationSlice.actions;

export const selectNotifications = (state: RootState) => state.notification;

export default notificationSlice.reducer;
