import { createSlice } from "@reduxjs/toolkit";

import { AuthState, loginResponseState } from "dto/authDto";
import { RootState } from "store/store";

let auth = localStorage.getItem("auth");

const initialState: AuthState = auth
  ? JSON.parse(auth)
  : {
      isLoggedIn: false,
      id: -1,
      name: "",
      userIdentifier: "",
      role: 2,
      isAdmin: false,
      authToken: "",
    };

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logIn: (state, Action: { payload: loginResponseState; type: string }) => {
      state.isLoggedIn = true;
      state.id = Action.payload.id;
      state.name = Action.payload.name;
      state.userIdentifier = Action.payload.userIdentifier;
      state.role = Action.payload.role;
      state.isAdmin = Action.payload.role === 1;
      state.authToken = Action.payload.token;
      localStorage.setItem("auth", JSON.stringify(state));
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.authToken = "";
      localStorage.setItem("auth", JSON.stringify(state));
    },

    updateName: (state, Action: { payload: string; type: string }) => {
      state.name = Action.payload;
      localStorage.setItem("auth", JSON.stringify(state));
    },
  },
});

export const { logIn, logOut, updateName } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
