import { LogLevel } from "@azure/msal-browser";
import { Box, Button, Dialog, DialogActions, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from "notistack";
import { useState } from "react";
import { removeCrashNotification } from "src/services/notificationService";
import { selectAuth } from "src/slices/authSlice";
import { useAppSelector } from "src/store/hooks";
import { Log } from "src/utilities/helper";
import DialogHeader from "../DialogBox/DialogHeader";

type RemoveNotificationButtonProps = {
    crashId: number,
    triggerUpdateData: any
}

export const RemoveNotificationButton = ({crashId, triggerUpdateData}: RemoveNotificationButtonProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const auth = useAppSelector(selectAuth);

    const remove = () => {
        let params = {
            crashId: crashId
        };

        removeCrashNotification(auth.authToken, params).then((response) => {
            if(response[0] && response[0].success) {
                enqueueSnackbar(`Crash-${crashId} has been removed from Notification successfully`, { variant: "success" });
                triggerUpdateData();
            }
            else {
                var error = response[0] ? response[0].message : response[1];
                Log(error, LogLevel.Error);
                enqueueSnackbar(`Crash-${crashId} could not be removed from Notification`, { variant: "error" });
            }
        })
    }
    return (
        <>
            <IconButton color={"error"} aria-label="delete" onClick={() => setShowConfirmDialog(true)}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={showConfirmDialog}
                onClose={() => {
                    setShowConfirmDialog(false);
                }}
                PaperProps={{ sx: { maxHeight: "35%", minWidth: "30%", p: 0.5 } }}>
                <DialogHeader
                    title={`Remove Crash Notification`}
                    onClose={() => {
                        setShowConfirmDialog(false);
                    }}/>
                <Box sx={{ pl: 2, pb: 0.5, pr: 2 }}>
                    {`This crash will be removed from the Notification. Are you sure?`}
                </Box>
                <DialogActions sx={{ pr: 2, pb: 1.75, pt: 2 }}>
                    <Button
                        sx={{ borderRadius: 0.5 }}
                        variant="outlined"
                        onClick={() => {
                            setShowConfirmDialog(false);
                        }}
                        autoFocus
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 0.5 }}
                        onClick={() => {
                            remove();
                            setShowConfirmDialog(false);
                        }}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}