import { LogLevel } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Backdrop,
  Box,
  ButtonBase,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "components/LogoSign";
import { apiRequest } from "configs/authConfig";
import { login } from "services/authService";
import { logIn } from "slices/authSlice";
import { runtimeEnv } from "slices/RuntimeEnvSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import dialogContext, { DialogType } from "utilities/dialogContext";
import { Log } from "utilities/helper";

const LoginButton = () => {
  const { instance } = useMsal();
  var scope = useAppSelector(runtimeEnv).scope;
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  var { setDialogType, setShowDialog, setTitle, setMessage, setOnClickOk } =
    useContext(dialogContext);

  apiRequest.scopes[0] = scope;

  const handleLogin = (): void => {
    setInProgress(true);
    instance
      .loginPopup(apiRequest)
      .then((response) => {
        login(response.accessToken).then((loginResponse: any) => {
          setInProgress(false);

          if (loginResponse[0]) {
            var result = loginResponse[0];
            if (result.success) {
              dispatch(logIn(result.data));
              navigate("/dashboard");
              Log(result.message, LogLevel.Info);
            } else {
              // Azure Auth is ok but user is not in Corsair DB or error in backend
              Log(result.message, LogLevel.Warning);

              setTitle("Login Failed");
              setMessage(result.message);
              setDialogType(DialogType.MESSAGE);
              setOnClickOk(() => () => setShowDialog(false));
              setShowDialog(true);
            }
          } else {
            // API call error
            var error = loginResponse[1];
            Log(error.message, LogLevel.Error);

            setTitle("Login Failed");
            setMessage(error.message);
            setDialogType(DialogType.MESSAGE);
            setOnClickOk(() => () => setShowDialog(false));
            setShowDialog(true);
          }
        });
      })
      .catch((e) => {
        setInProgress(false);
        // Azure Auth error
        setTitle("Login Failed");
        setMessage("Authentication with Azure AD failed");
        setDialogType(DialogType.MESSAGE);
        setOnClickOk(() => () => setShowDialog(false));
        setShowDialog(true);
        Log(e, LogLevel.Error);
      });
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={inProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        spacing={{ xs: 6, md: 10 }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item md={10} lg={8} mx="auto">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 0,
              mt: 10,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              py={5}
              alignItems="center"
              mb={-3}
            >
              <Logo />
            </Box>
            <Typography
              fontFamily={"roboto"}
              fontSize={28}
              color="black"
              sx={{ mb: 4, fontWeight: "bold" }}
            >
              Crash Reporting Service
            </Typography>
            <ButtonBase onClick={handleLogin}>
              <CardMedia
                component="img"
                image="static/images/logo/ms-button.svg"
                alt="Corsair"
              />
            </ButtonBase>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginButton;
