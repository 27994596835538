import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";

const initialState: Window["env"] = {
  clientId: "",
  authority: "",
  redirectUri: "",
  scope: "",
  baseURL: "/api/",
};

export const runtimeEnvSlice = createSlice({
  name: "runtimeEnv",
  initialState,
  reducers: {
    initializeRuntimeEnv: (state, action: PayloadAction<Window["env"]>) => {
      state = Object.assign(state, action.payload);
    },
  },
});

export const { initializeRuntimeEnv } = runtimeEnvSlice.actions;
export const runtimeEnv = (state: RootState) => state.runtimeEnv;
export default runtimeEnvSlice.reducer;
